import { useState, memo, useEffect, FC } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useShoppingCart } from '@contexts/ShoppingCartContext'
import useApiRequest from "@mbs-dev/api-request";
import { PricingProps, ProductProps, VariantProps } from '@helpers/Props'
import { FrCustomeErrorNorify } from '@helpers/Toastify'
import { DecodeData, ReactHelmet, apiUrl, handleImageLinkDrage, marqueImgUrl, marqueLogoUrl, productImgUrl, variantIconUrl, variantImgUrl } from '@helpers/Helpers'
import { SubmitButton, loadingHelper } from '@helpers/Index'
import ControlledAccordions from '@components/ControlledAccordions/ControlledAccordions'
// import ProductImagesGallery from '@components/Carousel/ProductImagesGallery/ProductImagesGallery'
import Icons from '@helpers/IconsHelper'
import ShareButtons from '@components/ShareButtons/ShareButtons'
import ReviewForm from '@components/Reviews/ReviewForm'
import BreadCrumbs from '@components/BreadCrumbs/BreadCrumbs'
import './ProductDetailsStyle.css'
import Layout from '@layouts/Layout'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import ProductImagesGallery from '@components/Carousel/ProductImagesGallery/ProductImagesGallery'

const ProductDetails: FC = () => {
    const { slug } = useParams()
    const { windowWidth } = useWindowWidthContext()
    const [cardItemQuantity, setCardItemQuantity] = useState(1)
    const { addToCart } = useShoppingCart()
    const { apiRequest } = useApiRequest();
    const [discount, setDiscount] = useState(null)
    const [product, setProduct] = useState<ProductProps>()
    const [selectedVariant, setSelectedVariant] = useState<any>('')
    const [selectedVariantImage, setSelectedVariantImage] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [description, setDescription] = useState('')
    const [ingredients, setIngredients] = useState('')
    const [howToUse, setHowToUse] = useState('')
    const [variantPrice, setVariantPrice] = useState<number>();
    const [isVariant, setIsVariant] = useState(false);
    const [selectedVariantId, setSelectedVariantId] = useState<number>();
    const { state } = useLocation()

    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false)
    const openOrderModal = () => {
        setIsOrderModalOpen(true)
    }

    const hideOrderModal = () => {
        setIsOrderModalOpen(false)
    }

    //   const product = state as ProductProps

    const imageStyles = 'img-fluid product-details-image d-flex justify-content-center'

    const fetchProductData = async () => {
        try {
            setIsLoading(true)
            const response = await apiRequest({
                route: `${apiUrl}/products.json?slug=${slug}`,
                method: 'GET',
            })

            if (response.status === 200) {
                setProduct(response.data[0])
                setIsLoading(false)
            } else {
                FrCustomeErrorNorify()
                setIsLoading(false)
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }

    const fetchPromotionData = async () => {
        try {

            if (product?.id) {
                const response = await apiRequest({
                    route: `${apiUrl}/promotion-discount/product_id/${product?.id}`,
                    method: 'GET',
                })

                if (response.status === 200) {
                    setDiscount(response.data.discount)
                } else {
                    FrCustomeErrorNorify()
                }
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        if (state) {
            setProduct(state)
        } else {
            fetchProductData()
        }
    }, [])

    useEffect(() => {
        fetchPromotionData()
    }, [product?.id])

    useEffect(() => {
        if (product && product.variant && product.variant?.length > 0) {
            setSelectedVariant(`${product.variant[0].reference} ${product.variant[0].label && product.variant[0].label}`)
            setDescription(product.variant[selectedIndex].description)
            setHowToUse(product.variant[selectedIndex].howToUse)
            setIngredients(product.variant[selectedIndex].ingredients)
            setSelectedVariantId(product.variant[selectedIndex].id)
            setIsVariant(true)
        }
    }, [product])

    useEffect(() => {
        if (discount !== null && product?.variant && product?.variant?.length > 0) {
            let sellingPrice = product?.variant[selectedIndex].sellingPrice

            if (sellingPrice !== product?.sellingPrice) {
                setVariantPrice(sellingPrice - (sellingPrice * (discount / 100)))
            } else {
                setVariantPrice(product?.sellingPrice - (product?.sellingPrice * (discount / 100)))
            }
        }
    }, [product, selectedIndex, discount])

    const increment = () => {
        setCardItemQuantity(prevItem => prevItem + 1)
    }

    const decrement = () => {
        if (cardItemQuantity > 1) {
            setCardItemQuantity(prevItem => prevItem - 1)
        }
    }

    if (!product) {
        return null
    }

    let productPrice = discount !== null ? (product.sellingPrice - (product.sellingPrice * (discount / 100))) : product.sellingPrice

    const Pricing = ({ price, selling }: PricingProps) => {
        return (
            <>
                <del className='me-2 discount-price mt-3'>{selling?.toFixed(2)} DH</del>
                <p className="product-details-prix mt-1 mb-5 clr-pink">{price?.toFixed(2)} DH</p>
            </>
        )
    }


    return (

        <Layout>
            <ReactHelmet title={`Beauty Hub - ${product.title}`} />

            {isLoading ?
                <div className="product-details mt-3 mx-3">
                    <loadingHelper.AnimatedLoading label='de produit' />
                </div>
                :
                <div className="product-details mt-5 pt-5 mb5 ox-hidden">
                    <div className="container">
                        <div className="row product-details-content d-flex justify-content-around">
                            <div className={`col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 ${windowWidth > 768 ? 'mt-5 mb-5' : 'mt-4 mb-2'}`}>
                                <div className={`product-image-gallery ${product.productImages.length < 1 ? 'd-flex justify-content-center' : ''}`}>
                                    {product.productImages && product.productImages.length > 0 && selectedVariantImage.length === 0 ?
                                        <ProductImagesGallery product={product} />
                                        : selectedVariantImage && product.variant && product.variant.length > 0 ?
                                            product.variant[selectedIndex].variantImages && product.variant[selectedIndex].variantImages.length > 0
                                                ?
                                                <ProductImagesGallery product={product} isVariant={true} index={selectedIndex} />
                                                :
                                                <img src={`${variantImgUrl}/${product.variant[selectedIndex].image}`}
                                                    alt={product.variant[selectedIndex].label}
                                                    className={imageStyles} />
                                            :
                                            <img src={`${productImgUrl}/${product.image}`}
                                                alt={product.title}
                                                className={imageStyles} />
                                    }
                                </div>
                                {product.video_url && (
                                    <div className="product-video mt-5 d-non">
                                        <div className="video-container">
                                            <iframe
                                                id="player"
                                                src={product.video_url.replace('watch?v=', 'embed/')}
                                                allowFullScreen
                                                title={product.title}
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-2 ps-3 ps-sm-1">
                                < BreadCrumbs product={product} />

                                <div className="product-details-info">
                                    <h1 className='product-details-title text-cap'>{product.title}</h1>
                                    {productPrice !== product.sellingPrice
                                        ? isVariant ?
                                            <Pricing price={variantPrice} selling={product.variant[selectedIndex].sellingPrice} />
                                            :
                                            <Pricing price={productPrice} selling={product.sellingPrice} />
                                        :
                                        <p className="product-details-prix mt-3 mb-3">{productPrice} DH</p>
                                    }

                                    <div className="product-details-total">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-6 product-details-total-text">
                                                Prix total :
                                            </div>
                                            <div className=" col-lg-6 col-md-6 col-6 product-details-total-price">
                                                {cardItemQuantity > 0 ?
                                                    isVariant && variantPrice ?
                                                        `${(variantPrice * Number(cardItemQuantity)).toFixed(2)} DH`
                                                        :
                                                        `${(productPrice * Number(cardItemQuantity)).toFixed(2)} DH`
                                                    :
                                                    isVariant ?
                                                        `${productPrice} DH`
                                                        :
                                                        `${variantPrice} DH`
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='hr-tag' />

                                    {/* --------- Start Variant */}
                                    {product.variant && product.variant?.length > 0 ?
                                        <div className={`row variant-row ${selectedVariant ? 'variant-row-details-m2' : 'variant-row-details-m1'}`}>
                                            <div className="variant-ref-content pb-1 ps-0">
                                                {selectedVariant ?
                                                    <span className='ff-outfit-m clr-var'>{selectedVariant}</span>
                                                    :
                                                    null}
                                            </div>
                                            {product.variant?.map((v: VariantProps, index: number) => {
                                                const sv = `${v.reference && v.reference} ${v.label && v.label}`
                                                return (
                                                    <div className={`variant-icon-content mx-1 d-flex-center c-pointer ${selectedVariant === sv ? 'selected-border' : 'notselected-border'}`}
                                                        key={v.id}
                                                        onClick={() => {
                                                            setSelectedVariant(`${v.reference && v.reference} ${v.label && v.label}`)
                                                            setDescription(v.description)
                                                            setHowToUse(v.howToUse)
                                                            setIngredients(v.ingredients)
                                                            setSelectedVariantImage(v.variantImages)
                                                            setSelectedIndex(index)
                                                            setSelectedVariantId(v.id)
                                                        }}>
                                                        <div className="variant-icon">
                                                            <img src={`${variantIconUrl}/${v.icon}`} alt="" className='variant-icon-img' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    {/* --------- End Variant */}


                                    {/* --------- Start Add To Cart */}
                                    <div className="row align-items-center mt-3">
                                        <div className={`col-md-4 col-lg-4 col-xl-4 col-12 ${windowWidth < 578 ? 'mb-2' : ''}`}>
                                            <div className="radius-10px shadow-1 py-12px mx-10px max-w-13rem d-flex-center text-center">
                                                <span className="fs-20px" onClick={() => decrement()}>
                                                    <Icons.HiMinusSm className='shopping-cart-item-quantity-icon fs-30px' />
                                                </span>
                                                <span className="px-25px fs-18px">{cardItemQuantity}</span>
                                                <span className="fs-20px" onClick={() => increment()}>
                                                    <Icons.BiPlus className='shopping-cart-item-quantity-icon fs-30px' />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-xl-5 col-lg-5 col-md-7 col-12">
                                            {/* <button onClick={() => addToCart(product.id, productPrice, 'product', cardItemQuantity)} type="submit" className="d-none btn addtocard-btn">
                                                <Icons.FiShoppingCart className='modal-card-icon' />Ajouter au panier
                                            </button> */}
                                            <SubmitButton
                                                btnLabel="Ajouter au panier"
                                                content={<Icons.FiShoppingCart className='addtocart-icon' />}
                                                // onClick={() => addToCart(product.id, isVariant ? Number(variantPrice) : productPrice, 'product', cardItemQuantity)}
                                                onClick={() => {
                                                    const variantId = selectedVariantId ? selectedVariantId : '';
                                                    if (product.variant && product.variant?.length > 0) {
                                                        addToCart(product.id, isVariant ? Number(variantPrice) : productPrice, 'product', variantId, cardItemQuantity, product.sellingPrice, true, product.marque.title);
                                                    } else {
                                                        addToCart(product.id, isVariant ? Number(variantPrice) : productPrice, 'product', '', cardItemQuantity, product.sellingPrice, false, product.marque.title);
                                                    }
                                                }}
                                                className="mt-1 px-2rem py-14px" />
                                        </div>

                                        {/* <div className={`col-xl-4 col-lg-4 col-md-5 col-12 ${windowWidth > 578 ? 'px-0' : ''}`}>
                                            <ReversSubmitButton
                                                onClick={() => openOrderModal()}
                                                btnLabel="Acheter"
                                                content={<Icons.FaShoppingBasket className='order-now-icon' />}
                                                className="mt-1" />
                                        </div> */}
                                    </div>
                                    {/* --------- End Add To Cart */}


                                    {/* --------- Start Description */}
                                    {product.description || product.howToUse || product.ingredients ?
                                        <hr className='hr-tag' />
                                        :
                                        null
                                    }
                                    <div className="row">

                                        {product.description && product.description !== null ?
                                            description ?
                                                <ControlledAccordions
                                                    id='description'
                                                    title={`Description`}
                                                    content={<DecodeData content={description} />}
                                                />
                                                :
                                                <ControlledAccordions
                                                    id='description'
                                                    title={`Description`}
                                                    content={<DecodeData content={product.description} />}
                                                />
                                            :
                                            null
                                        }

                                        {product.howToUse && product.howToUse !== null ?
                                            howToUse ?
                                                <ControlledAccordions
                                                    id='howtouse'
                                                    title={`Conseils d'Utilisation`}
                                                    content={<DecodeData content={howToUse} />}
                                                />
                                                :
                                                <ControlledAccordions
                                                    id='howtouse'
                                                    title={`Conseils d'Utilisation`}
                                                    content={<DecodeData content={product.howToUse} />}
                                                />
                                            :
                                            null
                                        }

                                        {product.ingredients && product.ingredients !== null ?
                                            ingredients ?
                                                <ControlledAccordions
                                                    id='ingredients'
                                                    title={`Ingredients`}
                                                    content={<DecodeData content={ingredients} />}
                                                />
                                                :
                                                <ControlledAccordions
                                                    id='ingredients'
                                                    title={`Ingredients`}
                                                    content={<DecodeData content={product.ingredients} />}
                                                />
                                            :
                                            null
                                        }
                                    </div>
                                    {/* --------- End Description */}

                                    {/* --------- Start Brand Image */}
                                    <div className="row o-hidden mt-8px mb-1rem" >
                                        <div className={`col-12 d-flex-start`}>
                                            <div className="m-2 marque-image-container">
                                                <Link to={`/boutique/marque/${product.marque.slug}`} >
                                                    <img
                                                        src={`${marqueLogoUrl}/${product.marque.logo}`}
                                                        className={`brand-carousel-logo w-80`}
                                                        alt={product.marque.title}
                                                        onDragStart={handleImageLinkDrage}
                                                    />
                                                    <img
                                                        src={`${marqueImgUrl}/${product.marque.image}`}
                                                        alt={product.marque.title}
                                                        className='nav-item-marque-image' />

                                                    <div className="brand-carousel-overlay d-flex-center c-pointer text-center">
                                                    </div>
                                                    <div className="brand-carousel-overlay-before d-flex-center c-pointer text-center">
                                                    </div>
                                                </Link>
                                            </div>

                                            {/* <div className={`nav-item-marque-title ${windowWidth < 578 ? 'text-center' : 'text-center ps-0'}`}>
                                                <Link className='bm-link' to={`/boutique/marque/${product.marque.slug}`} >
                                                    <p className='ff-varela-round fw-600 m-0 c-pointer'>{product.marque.title}</p>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* --------- End Brand Image */}

                                    {/* <hr className='hr-tag' /> */}
                                    <ShareButtons product={product} entity={'produit'} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="container mb-5 pb5 desc-section">
                        <hr className='hr-tag' />
                        <div className="row mt-5">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <ReviewForm productId={product.id} isPack={false} />
                            </div>

                        </div>
                    </div>
                </div >
            }
        </Layout>
    )
}

export default memo(ProductDetails)